// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/search.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/search.tsx");
  import.meta.hot.lastModified = "1715872054768.699";
}
// REMIX HMR END

import { Input } from "@/components/ui/input";
import { AppContext } from "@/root";
import { useFetcher } from "@remix-run/react";
import React, { useContext } from "react";
import z from "zod";
export async function loader({
  request
}) {
  const q = new URL(request.url).searchParams.get('q');
  if (!q) return [];
  console.log(q, 'q from LOADER search');
  const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${q}`);
  const data = await response.json();
  const companies = data._embedded.enheter;
  return {
    companies
  };
}
export const searchFormSchema = z.object({
  email: z.string().email(),
  phone: z.string().min(8)
});
export default function Search() {
  _s();
  const fetcher = useFetcher();
  const [isSelectItem, setIsSelectItem] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const {
    setEmail,
    setPhone,
    setCompanyId,
    errors
  } = useContext(AppContext);
  console.log("🚀 ~ file: search.tsx:26 ~ data:", fetcher?.data?.companies);
  const handleItemClick = item => {
    setInputValue(item.navn);
    setOpen(false);
    setIsSelectItem(true);
    setCompanyId(item.organisasjonsnummer);
  };
  return <div>
      <fetcher.Form method="get" action="/search">
        <Input type="text" name="q" value={inputValue} placeholder="search" onChange={event => {
        fetcher.submit(event.currentTarget.form);
        setInputValue(event.target.value);
      }} onFocus={() => setOpen(true)} />
      </fetcher.Form>

      {fetcher.data && fetcher.data.companies ? open && <ul className="py-3 absolute w-max h-64 overflow-y-scroll bg-white">
          {fetcher.data.companies.map((item, index) => <li key={index} className="max-w-[22.5rem] py-1 px-5 cursor-pointer hover:bg-gray-200" onClick={() => handleItemClick(item)}>
              {`${item.navn} / ${item.organisasjonsnummer}`}
            </li>)}
        </ul> : null}

      {isSelectItem && <div className="flex gap-2 pt-5">
          <div className="flex flex-col">
            <Input type="text" name="email" placeholder="Email" onChange={e => setEmail(e.target.value)} required />
            {errors.email && <span className="text-red-500 flex text-sm">{errors.email}</span>}
          </div>
          <div className="flex flex-col">
            <Input type="text" name="phone" placeholder="Phone" onChange={e => setPhone(e.target.value)} required />
            {errors.phone && <span className="text-red-500 flex text-sm">{errors.phone}</span>}
          </div>

          <Input type="text" name="value" className="hidden" defaultValue={inputValue} readOnly />
        </div>}
    </div>;
}
_s(Search, "KzfldKMicUUrn6LMHgIp+lFg75s=", false, function () {
  return [useFetcher];
});
_c = Search;
var _c;
$RefreshReg$(_c, "Search");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;